import { IDataPoint } from 'src/components/charts/dashboard/util'
import { IRegisteredIssuesGraphData } from 'src/components/issue-kpi/RegisteredIssuesGraphs'
import { ITaskStatusPieData } from 'src/components/issue-kpi/ResolvedOverDueGraphs'
import { IFilterParams } from 'src/page/task/IssueStatPage'
import { ITask, ITaskData, ITaskType } from './OrgTypes'
import { request } from './service-utils'

export const createTask = (taskData: Partial<ITaskData>): Promise<ITask> => {
  return request(`tasks`, 'POST', taskData)
}

export const editTask = (
  taskData: NullablePartial<ITaskData | ITask>,
): Promise<ITask> => {
  return request(`tasks/${taskData.id}`, 'PUT', taskData)
}

export const getTask = (projectId: number, taskId: number): Promise<any> => {
  return request(`projects/${projectId}/tasks/${taskId}`, 'GET')
}

export const deleteTask = (task: ITaskData) => {
  return request(`/tasks/${task.id}`, 'DELETE', {}, true)
}

export const createTaskType = (
  taskData: ITaskType,
  projectId: number,
): Promise<ITaskType> => {
  return request(`projects/${projectId}/task_types`, 'POST', taskData)
}

export const editTaskType = (
  taskType: NullablePartial<ITaskType>,
): Promise<ITaskType> => {
  return request(`task_types/${taskType.id}`, 'PUT', taskType)
}

export const getProjectTaskTypes = (
  projectId: number,
): Promise<ITaskType[]> => {
  return request(`projects/${projectId}/task_types`, 'GET')
}

export const getProjectTaskData = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/task_data`, 'GET')
}

export const deleteTaskType = (task: ITaskType) => {
  return request(`/task_types/${task.id}`, 'DELETE', {}, true)
}

export const getTaskDisciplineCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(`/projects/${projectId}/task_discipline_canvas`, 'POST', data)
}

export const getTaskMainprocessCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `/projects/${projectId}/task_main_process_canvas`,
    'POST',
    data,
  )
}

export const getTaskResponsibleCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(`/projects/${projectId}/task_responsible_canvas`, 'POST', data)
}

export const getImprovementTasks = (
  improvementId: number,
): Promise<ITaskData[]> => request(`improvements/${improvementId}/tasks`, 'GET')

/**
 * Filter end points
 */
export const getFilterTasks = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
  parentType?: string,
) => {
  return request(
    `projects/${projectId}/filter_tasks/${
      parentType ? parentType + '/' : ''
    }pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const massUpdateTasks = (
  projectId: number,
  taskIds: number[],
  task: Partial<ITask>,
): Promise<any> => {
  return request(`projects/${projectId}/tasks/mass_update`, 'POST', {
    ...task,
    task_ids: taskIds,
  })
}

export const getWagonTasksFiltered = (
  wagonId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
) => {
  return request(
    `construction_locomotives/${wagonId}/tasks/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getFilterItemForDashbord = (
  projectId: number,
  userId: number,
  taskType: string,
  currentPage: number,
  itemsPerPage: number,
  filter: any,
  status?: string,
) => {
  return request(
    `projects/${projectId}/users/${userId}/${taskType}/pages/${currentPage}/${itemsPerPage}${
      status ? `?status=${status}` : ''
    }`,
    'POST',
    filter,
  )
}

export const getOrgFilterItemForDashbord = (
  orgId: number,
  userId: number,
  taskType: string,
  currentPage: number,
  itemsPerPage: number,
  filter: any,
  status?: string,
) => {
  return request(
    `organizations/${orgId}/users/${userId}/${taskType}/pages/${currentPage}/${itemsPerPage}${
      status ? `?status=${status}` : ''
    }`,
    'POST',
    filter,
  )
}

export const getProjectTaskPPUData = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/ppus/task`, 'GET')
}

export const deleteBulkTasks = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/tasks/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const getTaskTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/tasks/tree`, 'GET')
}

export const getTaskTypesTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/task_types/tree`, 'GET')
}

export const getTaskType = (taskTypeId: number): Promise<any> => {
  return request(`/task_types/${taskTypeId}`, 'GET')
}

export const getTaskSteps = (
  projectId: number,
  taskId: number,
): Promise<any> => {
  return request(`/projects/${projectId}/tasks/${taskId}/task_steps`, 'GET')
}

export const updateNextTaskStep = (
  projectId: number,
  taskId: number,
  currentStepId: number,
  nextStepId: number,
  conclusion: string,
  closed_by: number,
): Promise<ITaskType> => {
  return request(`/projects/${projectId}/tasks/${taskId}/next_step`, 'PUT', {
    current_step_id: currentStepId,
    next_step_id: nextStepId,
    comment: conclusion,
    closed_by: closed_by,
  })
}

export const getAccumulatedTaskCountData = (
  projectId: number,
  body: IFilterParams,
): Promise<{ tasks_chart_data: IDataPoint[] }> => {
  return request(
    `/projects/${projectId}/tasks_registered_chart_data`,
    'POST',
    body,
  )
}

export const getTaskTypeDevelopmentData = (
  projectId: number,
  body: Omit<IFilterParams, 'task_type'>,
): Promise<{ tasks_chart_data: IDataPoint[] }> => {
  return request(
    `/projects/${projectId}/tasks_per_task_type_chart_data`,
    'POST',
    body,
  )
}

export const getTaskTypeDistributionData = (
  projectId: number,
  body: Omit<IFilterParams, 'task_type'>,
): Promise<{ task_type_pie_data: IRegisteredIssuesGraphData[] }> => {
  return request(
    `/projects/${projectId}/task_types_pie_chart_data`,
    'POST',
    body,
  )
}

export const getTaskStatusCountData = (
  projectId: number,
  body: IFilterParams,
): Promise<{ task_status_pie_data: ITaskStatusPieData[] }> => {
  return request(
    `/projects/${projectId}/tasks_status_pie_chart_data`,
    'POST',
    body,
  )
}

export const addRelatedTasks = (
  projectId: number,
  taskId: number,
  relatedTaskIds: number[],
): Promise<any> => {
  return request(
    `projects/${projectId}/tasks/${taskId}/add_related_tasks`,
    'POST',
    {
      related_task_ids: relatedTaskIds,
    },
  )
}

export const removeRelatedTasks = (
  projectId: number,
  taskId: number,
  relatedTaskIds: number[],
): Promise<any> => {
  return request(
    `projects/${projectId}/tasks/${taskId}/remove_related_tasks`,
    'POST',
    {
      related_task_ids: relatedTaskIds,
    },
  )
}
